import * as React from "react"
import { graphql } from "gatsby"
import { Helmet, HelmetProvider } from 'react-helmet-async'

//On importe les composants
import Header from '../components/header'
import Footer from '../components/footer'
import Formulaire from '../components/formulaire'
import Produits from '../components/produits'

//On importe les images
import Favicon from '../images/favicon.jpg'
import Dimension from '../images/dimensions.svg'
import Firewood from '../images/firewood.svg'
import Humidity from '../images/humidity.svg'
import Meter from '../images/meter.svg'
import Rainy from '../images/rainy.svg'
import Trunk from '../images/trunk.svg'
import Weight from '../images/weight.svg'
import Wooden from '../images/wooden.svg'

//On importe le css
import '../style/pageproduits.scss'



export default function PageProduits({ data }) {

    const info = data.allSitePage.nodes[0].pageContext


    return (

        <HelmetProvider>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <link rel="icon" href={Favicon} />

                <title>{info.titrepage}</title>
                <html lang="fr" />
            </Helmet>

            <div className="BackGroundIndex">


                <Header />

                <div className="MainPageProduits">

                    <h1 align="center">{info.nomproduit}</h1>

                    <div className="ContainerPageProduits">


                        <div className="BlockPageProduits">
                            <img src={info.image} alt={info.titrepage} />
                        </div>


                        <div className="BlockPageProduits">

                            <div className="ItemPageProduits">

                                <img src={Meter} width="70px" alt="dimension des bûches" />
                                <p>{info.buche}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Trunk} width="70px" alt="essences de bois" />
                                <p>{info.essence}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Humidity} width="70px" alt="taux d'humidité du bois" />
                                <p>{info.humidite}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Weight} width="70px" alt="poids du produit" />
                                <p>{info.poids}</p>

                            </div>

                        </div>

                        <div className="BlockPageProduits">

                            <div className="ItemPageProduits">

                                <img src={Dimension} width="70px" alt="dimension du produit" />
                                <p>{info.dimension}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Firewood} width="70px" alt="volume de bois" />
                                <p>{info.volume}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Rainy} width="70px" alt="stockage du bois" />
                                <p>{info.stockage}</p>

                            </div>

                            <div className="ItemPageProduits">

                                <img src={Wooden} width="70px" alt="gerbage du produit" />
                                <p>{info.gerbage}</p>

                            </div>

                        </div>

                    </div>

                    <div className="DescriptionPageProduits">

                        <h2>DESCRIPTION</h2>

                        <p align="justify" dangerouslySetInnerHTML={{ __html: info.description }} />


                    </div>


                    <div className="VideosPageProduits">
                        <div className="VideoPageProduits">
                            <iframe src={info.video1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        {console.log(info.video1)}
                        <div className="VideoPageProduits">
                            <iframe src={info.video2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>



                </div>

            </div>

            < Produits />

            <Formulaire />


            <Footer />

        </HelmetProvider>

    )
}

export const pageProduct = graphql`
    query currentProductPage($path: String!) {
        allSitePage(filter: {path: { eq: $path }}) {
            nodes{
                pageContext
            }
      }
    }
`